export default class DartBoardDrawingUtil  {
  data: { game_dart_ideal: any[]; game_dart_current: any[]; values_board: {}; };
  width: number;
  height: number;
  isAnimate: boolean;
  current_velos: number;
  slope: number;
  b: number;
  endx: number;
  endy: number;
  veloi_length: number;
  steps: number;
  clicked_sectors: any[];
  veloi: any[];
  sectors: any[];
  clicked_sector: any[];

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  flashMessageRef: HTMLDivElement;

  constructor(
    ctx,
    canvas,
    flashMessageRef,
  ) {
    this.data = {
      game_dart_ideal: [],
      game_dart_current: [],
      values_board: {}
    };

    this.width = 800;
    this.height = 400;
    this.isAnimate = false;
    this.current_velos = 0;
    this.slope = 1;
    this.b = 0;
    this.endx = 0;
    this.endy = 0;
    this.veloi_length = 0;
    this.steps = 360;
    this.clicked_sectors = [];
    this.veloi = [];
    this.sectors = [];
    this.clicked_sector = [];
    this.ctx = ctx;
    this.canvas = canvas;
    this.flashMessageRef = flashMessageRef;

    this.initVelos();
  }

  writeMessage = (context, message) => {
    context.font = '18pt Calibri';
    context.fillStyle = 'black';
    context.fillText(message, 10, 25);
  }

  writeMessagePos = (txt, font, x, y, color) => {
    this.ctx.save();
    this.ctx.font = font;
    this.ctx.textBaseline = 'top';
    this.ctx.fillStyle = 'white';
    var width = this.ctx.measureText(txt).width;
    this.ctx.fillRect(x, y, width, parseInt(font, 18));
    this.ctx.fillStyle = color;
    this.ctx.fillText(txt, x, y);
    this.ctx.restore();
  }

  getMousePos = (event) => {
    const rect = this.canvas.getBoundingClientRect();
    return {
      x : event.clientX - rect.left,
      y : event.clientY - rect.top
    };
  }

  cartesian2Polar = (x, y) => {
    const distance = Math.sqrt((x - 250) * (x - 250) + (y - 150) * (y - 150));
    let radians = Math.atan2(y - 150, x - 250);

    if (this.current_velos == 4) {
      radians = Math.abs(radians);
    }

    const polarCoordinates = {
      distance : distance,
      radians : radians
    };
    return polarCoordinates;
  }

  circle = (x, y, r, color) => {
    this.ctx.beginPath();
    this.ctx.arc(x, y, r, 0, Math.PI * 2, true);
    this.ctx.closePath();
    this.ctx.fillStyle = color;
    this.ctx.fill();
    this.ctx.lineWidth = 5;
    this.ctx.strokeStyle = '#003300';
    this.ctx.stroke();
  }

  drawArc = (x, y, r, ph1, ph2, color) => {
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.arc(x, y, r, ph1, ph2, true);
    this.ctx.closePath();
    this.ctx.fillStyle = color;
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#11111';
    this.ctx.fill();
    this.ctx.stroke();
  }

  drawLine = (x0, y0, x1, y1) => {
    this.ctx.beginPath();
    this.ctx.moveTo(x0, y0);
    this.ctx.lineTo(x1, y1);
    this.ctx.stroke();
  }

  fillCanvas = () => {
    this.ctx.beginPath();
    this.ctx.rect(0, 0, 500, 300);
    this.ctx.fillStyle = "white";
    this.ctx.fill();
  }

  handleCanvasClick = (event) => {
    if (!this.isAnimate) {
      const mousePos = this.getMousePos(event);
      const polarCoordinates = this.cartesian2Polar(mousePos.x, mousePos.y);

      if (this.checkClickPosition(polarCoordinates)) {
        this.endx = mousePos.x;
        this.endy = mousePos.y;
        this.slope = (this.endy - this.veloi[this.current_velos].fromy) / (this.endx - this.veloi[this.current_velos].fromx);
        this.b = this.endy - this.slope * this.endx;
        this.clicked_sectors.push([polarCoordinates.distance, this.current_velos]);
        this.isAnimate = true;

        return this.clicked_sectors.length;
      }
    }
  }

  init = () => {
    // Init veloi
    this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
    this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
    this.veloi[3].init(this.ctx, 50, 250, 50, 220, 2);
    this.veloi[2].init(this.ctx, 70, 250, 70, 220, 2);
    this.veloi[1].init(this.ctx, 90, 250, 90, 220, 2);
    this.veloi[0].init(this.ctx, 110, 250, 110, 220, 2);

    this.veloi_length = this.veloi.length;
    this.drawArc(250, 150, 105, Math.PI / 6, 11 * Math.PI / 6, 'gray');
    // Friendship
    this.drawArc(250, 150, 110, 11 * Math.PI / 6, 3 * Math.PI / 2, 'gray');
    // Work
    this.drawArc(250, 150, 105, 1.5 * Math.PI, 7 * Math.PI / 6, 'gray');
    // Health
    this.drawArc(250, 150, 110, 7 * Math.PI / 6, 5 * Math.PI / 6, 'gray');
    // Relationship
    this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');
    // Add event Listener on click

    return setInterval(this.animate, 2);
  }

  insideArea = (polarCoordinates) => {
    if (polarCoordinates.distance < 100 && this.sectors[this.current_velos]) {
      if ( polarCoordinates.radians >= this.sectors[this.current_velos].first &&
           polarCoordinates.radians < this.sectors[this.current_velos].second) {
        console.log('InsideArea : True  ');
        return 1;
      } else {
        console.log('InsideArea : False ');
        return 0;
      }
    } else {
      return false;
    }
  }

  checkClickPosition = (mousePos) => {
    if (this.insideArea(mousePos)) {
      return 1;
    } else
      return 0;
  }

  animate = () => {
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.drawOnScreen();

    if (this.current_velos < this.veloi_length) {
      if (this.isAnimate) {
        if (this.veloi[this.current_velos].toy > this.endy) {
          this.veloi[this.current_velos].toy = this.veloi[this.current_velos].toy - 1;
          this.veloi[this.current_velos].fromy = this.veloi[this.current_velos].fromy - 1;
          this.veloi[this.current_velos].tox = (this.veloi[this.current_velos].toy - this.b) / this.slope;
          this.veloi[this.current_velos].fromx = (this.veloi[this.current_velos].fromy - this.b) / this.slope;
          this.veloi[this.current_velos].draw(this.ctx);

        } else {
          this.isAnimate = false;
          this.current_velos = this.current_velos + 1;
        }
      } else {
        this.veloi[this.current_velos].shotPosition();
      }
    }
  }

  undo = () => {
    if (this.current_velos > 0 && this.current_velos <= 6) {
      this.current_velos = this.current_velos - 1;
      this.clicked_sectors[this.clicked_sectors.length - 1] = [];
      switch (this.current_velos) {
        case 0:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
          this.veloi[3].init(this.ctx, 50, 250, 50, 220, 2);
          this.veloi[2].init(this.ctx, 70, 250, 70, 220, 2);
          this.veloi[1].init(this.ctx, 90, 250, 90, 220, 2);
          this.veloi[0].init(this.ctx, 110, 250, 110, 220, 2);
          break;
        case 1:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
          this.veloi[3].init(this.ctx, 50, 250, 50, 220, 2);
          this.veloi[2].init(this.ctx, 70, 250, 70, 220, 2);
          this.veloi[1].init(this.ctx, 90, 250, 90, 220, 2);
          break;
        case 2:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
          this.veloi[3].init(this.ctx, 50, 250, 50, 220, 2);
          this.veloi[2].init(this.ctx, 70, 250, 70, 220, 2);
          break;
        case 3:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
          this.veloi[3].init(this.ctx, 50, 250, 50, 220, 2);
          break;
        case 4:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          this.veloi[4].init(this.ctx, 30, 250, 30, 220, 2);
          break;
        case 5:
          this.veloi[5].init(this.ctx, 10, 250, 10, 220, 2);
          break;
        default:
          break
      }
    }
  }

  drawOnScreen = () => {
    this.fillCanvas();

    this.circle(250, 150, 100, 'green');
    this.circle(250, 150, 80, 'white');
    this.circle(250, 150, 60, 'green');
    this.circle(250, 150, 40, 'white');
    this.circle(250, 150, 20, 'green');

    this.drawLine(250, 50, 250, 250);
    this.drawLine(163.4, 100, 336.6, 200);
    this.drawLine(336.6, 100, 163.4, 200);

    switch (this.current_velos) {
      case 0:
        // Family
        //drawArc(250, 150, 110, Math.PI / 2,  Math.PI / 6, 'gray');
        // Education
        this.drawArc(250, 150, 105, Math.PI / 6, 11 * Math.PI / 6, 'gray');
        // Friendship
        this.drawArc(250, 150, 110, 11 * Math.PI / 6, 3 * Math.PI / 2, 'gray');
        // Work
        this.drawArc(250, 150, 105, 1.5 * Math.PI, 7 * Math.PI / 6, 'gray');
        // Health
        this.drawArc(250, 150, 110, 7 * Math.PI / 6, 5 * Math.PI / 6, 'gray');
        // Relationship
        this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');
        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Family'</span> pie piece</strong>";
        break;
      case 1:
        // Friendship
        this.drawArc(250, 150, 110, 11 * Math.PI / 6, 3 * Math.PI / 2, 'gray');
        // Work
        this.drawArc(250, 150, 105, 1.5 * Math.PI, 7 * Math.PI / 6, 'gray');
        // Health
        this.drawArc(250, 150, 110, 7 * Math.PI / 6, 5 * Math.PI / 6, 'gray');
        // Relationship
        this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');

        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Education'</span> pie piece</strong>";
        break
      case 2:
        // Work
        this.drawArc(250, 150, 105, 1.5 * Math.PI, 7 * Math.PI / 6, 'gray');
        // Health
        this.drawArc(250, 150, 110, 7 * Math.PI / 6, 5 * Math.PI / 6, 'gray');
        // Relationship
        this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');
        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Friendships'</span> pie piece</strong>";
        break;
      case 3:
        // Health
        this.drawArc(250, 150, 110, 7 * Math.PI / 6, 5 * Math.PI / 6, 'gray');
        // Relationship
        this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');
        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Work'</span> pie piece</strong>";
        break;
      case 4:
        // Relationship
        this.drawArc(250, 150, 105, 5 * Math.PI / 6, Math.PI / 2, 'gray');
        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Health'</span> pie piece</strong>";
        break;
      case 5:
        this.flashMessageRef.innerHTML = "<strong>Shoot on <span style='color:green'>'Relationships'</span> pie piece</strong>";
        break;
      case 6:
        this.flashMessageRef.innerHTML = "<strong><span style='color:green'>Thanks!</span> </strong>";
        break;
      default:
        break;
    }

    this.writeMessagePos("Work", '14pt Calibri', 100, 30, 'green');
    this.writeMessagePos("Friendships", '14pt Calibri', 320, 30, 'green');
    this.writeMessagePos("Education", '14pt Calibri', 360, 150, 'green');
    this.writeMessagePos("Family", '14pt Calibri', 320, 260, 'green');
    this.writeMessagePos("Relationships", '14pt Calibri', 100, 260, 'green');
    this.writeMessagePos("Health", '14pt Calibri', 80, 150, 'green');

    for ( let i = 0; i < this.veloi_length; i++) {
      this.veloi[i].draw();
    }
  }


  initVelos = () => {
    const velos1 = {
      context : 0,
      fromx : 0,
      fromy : 0,
      tox : 0,
      toy : 0,
      r : 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();

        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.fillStyle = 'steelblue';
        this.context.stroke();
      }
    }

    const velos2 = {
      context: 0,
      fromx: 0,
      fromy: 0,
      tox: 0,
      toy: 0,
      r: 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();
        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.stroke();
      }
    }

    const velos3 = {
      context: 0,
      fromx: 0,
      fromy: 0,
      tox: 0,
      toy: 0,
      r: 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();
        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.stroke();
      }
    }

    const velos4 = {
      context: 0,
      fromx: 0,
      fromy: 0,
      tox: 0,
      toy: 0,
      r: 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();
        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.stroke();
      }
    }

    const velos5 = {
      context: 0,
      fromx: 0,
      fromy: 0,
      tox: 0,
      toy: 0,
      r: 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();
        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.stroke();
      }
    }

    const velos6 = {
      context: 0,
      fromx: 0,
      fromy: 0,
      tox: 0,
      toy: 0,
      r: 0,
      shotPosition: function() {
        this.fromx = 250;
        this.fromy = 300;
        this.tox = 250;
        this.toy = 270;
      },
      init: function(context, fromx, fromy, tox, toy, r) {
        this.context = context;
        this.fromx = fromx;
        this.fromy = fromy;
        this.tox = tox;
        this.toy = toy;
        this.r = r;
      },
      draw: function() {
        var x_center = this.tox;
        var y_center = this.toy;
        var angle;
        var x;
        var y;
        this.context.lineWidth = 4;
        this.context.strokeStyle = 'red';
        this.context.lineJoin = 'miter';

        this.context.beginPath();
        this.context.moveTo(this.tox, this.toy);
        this.context.lineTo(this.fromx, this.fromy);
        this.context.stroke();
        this.context.moveTo(this.fromx - 10, this.fromy - 10);
        angle = Math.atan2(this.toy - this.fromy, this.tox - this.fromx);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.moveTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;
        this.context.lineTo(x, y);

        angle += (1 / 3) * (2 * Math.PI);
        x = this.r * Math.cos(angle) + x_center;
        y = this.r * Math.sin(angle) + y_center;

        this.context.lineTo(x, y);
        this.context.closePath();
        this.context.stroke();
      }
    }

    this.veloi.push(velos1);
    this.veloi.push(velos2);
    this.veloi.push(velos3);
    this.veloi.push(velos4);
    this.veloi.push(velos5);
    this.veloi.push(velos6);

    this.sectors[0] = {
      first: Math.PI / 6,
      second: Math.PI / 2,
      name: 'Family',
      number: 6
    }
    this.sectors[1] = {
      first: -Math.PI / 6,
      second: Math.PI / 6,
      name: 'Work',
      number: 1
    };
    this.sectors[2] = {
      first: -Math.PI / 2,
      second: -Math.PI / 6,
      name: 'Friendships',
      number: 2
    }
    this.sectors[3] = {
      first: -5 * Math.PI / 6,
      second: -Math.PI / 2,
      name: 'Education',
      number: 3
    }
    this.sectors[4] = {
      first: 5 * Math.PI / 6,
      second: 7 * Math.PI / 6,
      name: 'Health',
      number: 5
    }
    this.sectors[5] = {
      first: -5 * Math.PI / 6,
      second: 5 * Math.PI / 6,
      name: 'Relationships',
      number: 4
    }
  }
}
