export const GameBoardResponses = {
  0: "Love your job",
  1: "Travel",
  2: "Get an illness",
  3: "Be successful",
  4: "Cheat others",
  5: "Be honored",
  6: "Unemployment",
  7: "Inspire others",
  8: "Be honest",
  9: "Crushing debt",
  10: "Financial security",
  11: "Live with parents forever",
  12: "Change the world",
  13: "Go to prison",
  14: "Be creative",
  15: "Get dumped",
  16: "Lose keys and wallet daily",
  17: "Work hard",
  18: "Get rejected",
  19: "Be brave",
  20: "Never finish anything",
  21: "Love others",
  22: "Be reckless",
  23: "Persist",
  24: "Have integrity",
  25: "Hate your job",
  26: "Lead others",
  27: "Develop gambling problem",
  28: "Be strong and healthy",
  29: "Turn to a life of crime",
  30: "Be adventurous",
  31: "Take from others",
  32: "Be lazy",
  33: "Live free",
  34: "Fight with others non-stop",
  35: "Be kind to yourself and others",
  36: "Steal",
  37: "Always learning",
  38: "Financial ruin",
  39: "Help others",
  40: "Marriage or long-term partnered",
  41: "Embezzle money",
  42: "Fight for peace",
  43: "Become a scrooge",
  44: "Wisdom",
  45: "Addiction",
  46: "Protect the environment",
  47: "No job, ever",
  48: "Avoid doing anything hard",
  49: "Experience beauty",
  50: "Compulsive lying",
  51: "To be loved",
  52: "Get fired",
  53: "Care for others",
  54: "Never trust anyone",
  55: "Seek truth",
  56: "Speak out against injustice",
  57: "Be mean",
  58: "Be someone's hero or role model",
  59: "Be grumpy",
  60: "Be responsible ",
  61: "Hold grudges forever",
  62: "Be authentic",
  63: "Judge others",
}

export const maxAllowedSelectedCells = 5;