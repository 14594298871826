import { FC } from "react";

interface Props {
  image: string;
  text?: string;
}

const Testimonial: FC<Props> = ({ text, image }) => {
  return (
    <div>
      <img className="testimonial" src={`/images/${image}`} />
    </div>
  );
};

export default Testimonial;
