import { FC, useRef, useEffect } from "react";
import DartBoardDrawingUtil from "../../services/dartboardDrawingUtil";

interface Props {
  boldText: string;
  navigateContent: Function;
}

const DartBoard: FC<Props> = ({ boldText, navigateContent }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const flashMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const flashMessage = flashMessageRef.current;
    const ctx = canvas?.getContext("2d");

    if (!canvas || !flashMessage) return;

    const dartBoardDrawingUtil = new DartBoardDrawingUtil(
      ctx,
      canvas,
      flashMessage
    );
    (window as any).dartBoardDrawingUtil = dartBoardDrawingUtil;

    dartBoardDrawingUtil.init();
  }, []);

  const handleDartBoardClick = (event) => {
    if ((window as any).dartBoardDrawingUtil) {
      const clickedSectors = (
        window as any
      ).dartBoardDrawingUtil.handleCanvasClick(event);

      /* final click/selection */
      if (clickedSectors === 6) {
        setTimeout(() => {
          navigateContent(1);
        }, 1500);
      }
    }
  };

  return (
    <div className="dart-wrapper">
      <div id="velakia" className="velakia">
        <p className="text-center">
          <strong id="velakia-txt">
            Throw a dart at each pie piece of the dart board one at a time,
            starting with the “family” section, based on <span>{boldText}</span>{" "}
            Closer to the center means more important.
          </strong>
        </p>
        <canvas
          onClick={(event) => handleDartBoardClick(event)}
          ref={canvasRef}
          id="canvas"
          width="500"
          height="310"
        >
          If you can see this, your browser does not support Canvas
        </canvas>
        <button
          id="undo"
          className="btn btn-primary"
          style={{ backgroundColor: "gray", borderColor: "gray" }}
          onClick={() => dartBoardDrawingUtil.undo()}
        >
          Undo <i className="fa fa-undo"></i>
        </button>
        <div ref={flashMessageRef} id="flash-message"></div>
      </div>
    </div>
  );
};

export default DartBoard;
