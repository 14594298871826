import { FC, Suspense } from "react";
import SessionNav from "./SessionNav";
import ApiService from "../services/apiService";
import { useParams, useSearchParams } from "react-router-dom";
import ContentComponents from "./ContentComponents";
import LoadingSpinner from "./atoms/LoadingSpinner";

const Sessions: FC = () => {
  const params = useParams();
  const sessionNumber = parseInt(params.number);
  const componentNumber = parseInt(params.module);
  const stepNumber = parseInt(params.step);
  const [searchParams] = useSearchParams();
  const profileResource = ApiService.instance().getProfile(searchParams);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <main className="session-detail">
        <SessionNav
          resource={profileResource}
          sessionNumber={sessionNumber}
          stepNumber={stepNumber}
          componentNumber={componentNumber}
        />
        <section id="experience">
          <ContentComponents
            sessionNumber={sessionNumber}
            componentNumber={componentNumber}
            stepNumber={stepNumber}
            resource={profileResource}
          />
        </section>
      </main>
    </Suspense>
  );
};

export default Sessions;
