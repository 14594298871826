import { FC } from "react";
import { Profile } from "../models/profile";
import { SuspenseResource } from "../services/apiService";
import SessionGridCard from "./SessionGridCard";

interface SessionGridProps {
  resource: SuspenseResource<Profile>;
}

const SessionGrid: FC<SessionGridProps> = ({ resource }) => {
  const profile = resource.read();
  const sessions = profile.sessions;

  return (
    <section className="sessions">
      <h2>Sessions</h2>
      {profile.completed_all ? (
        <>
          <p>
            You may have noticed that this program is all about living the life
            that you really want, and decisions about whether or not you'll
            smoke or vape are choices about how you want to live. Whether you
            have quit, cut back, or made some mental notes about things you
            might try in the future, you're finishing this program in a
            different place than where you started.
          </p>
          <p>
            We've covered a LOT of stuff in the 6 sessions, so feel free to come
            back and review any of it as needed. And if I don't see you again, I
            wish you well!
          </p>
        </>
      ) : profile.started ? (
        <>
          <p>I'm so glad you're back!</p>
          <p>
            Remember, a new session of EQQUAL—Empowered, Queer, Quitting, and
            Living--will be available every 3 days. You'll receive a text when
            one becomes available.
          </p>
        </>
      ) : (
        <p>
          The program includes 6 sessions along with text messages. You can
          access Session 1 now and a new session will be available every 3 days.
          That gives you time to try out new things between sessions.
        </p>
      )}

      <ul className="cards">
        {sessions.map((session) => (
          <SessionGridCard
            key={session.id}
            color={session.color}
            title={session.title}
            progress={session.progress * 100}
            duration={session.duration}
            locked={session.locked}
            inProgress={session.in_progress}
            session={session}
          />
        ))}
      </ul>
    </section>
  );
};

export default SessionGrid;
