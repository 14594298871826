import { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Sessions from "./Sessions";
import SignIn from "./SignIn";

export default class App extends Component {
  render() {
    return (
      <div id="app">
        <Router>
          <Header />

          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/sessions/:number/modules/:module/steps/:step"
                element={<Sessions />}
              />
              <Route path="/sign-in" element={<SignIn />} />
            </Routes>
          </ErrorBoundary>

          <Footer />
        </Router>
      </div>
    );
  }
}
