import React, { FC, useRef } from "react";
import HeroImage from "../../images/jen-cut-out.png";
import { Profile } from "../models/profile";
import { SuspenseResource } from "../services/apiService";
import Confetti from './Confetti';

interface Props {
  resource: SuspenseResource<Profile>;
}

const Hero: FC<Props> = ({ resource }) => {
  const profile = resource.read();
  const currentSession = profile.sessions.find(
    (item) => item.number == profile.highest_session_number_unlocked
  );
  const resumeComponentNumber = currentSession.resume_component_number;

  const heroRef = useRef<HTMLDivElement>(null);

  return (
    <section className="welcome" ref={heroRef}>
      <div className="headline">
        {profile.completed_all ? (
          <>
            <h2>Congratulations, {profile.name}!</h2>
            <p>You’ve completed all 6 sessions in the EQQUAL program!</p>
            <p className="hint">(Click for a surprise!)</p>
            <Confetti heroRef={heroRef} />
          </>
        ) : profile.started ? (
          <>
            <h2>Welcome Back, {profile.name}!</h2>
            <p className="return">
              I'm here to help you get closer to the life that you want!
            </p>
            <div className="cta">
              <a
                href={`/sessions/${profile.highest_session_number_unlocked}/modules/${resumeComponentNumber}/steps/1`}
              >
                Continue Your Journey
              </a>
            </div>
          </>
        ) : (
          <>
            <h2>Welcome {profile.name}!</h2>
            <p>Everyone’s journey to quitting smoking starts somewhere.</p>
          </>
        )}
      </div>
      <div className="jen">
        <div className="image-container">
          <img src={HeroImage} alt="jen image" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
