import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Profile } from "../models/profile";
import { SuspenseResource } from "../services/apiService";

interface Props {
  sessionNumber: number;
  componentNumber: number;
  stepNumber: number;
  resource: SuspenseResource<Profile>;
}

const SessionNav: FC<Props> = ({
  sessionNumber,
  componentNumber,
  resource,
}) => {
  const navigate = useNavigate();
  const sessions = resource.read().sessions;
  const session = sessions.find((item) => item.number == sessionNumber);

  const navigateToComponent = (component) => {
    if (component.locked) return;
    navigate(`/sessions/${session.number}/modules/${component.number}/steps/1`);
  };

  return (
    <nav
      id="session_navigation"
      data-color={session.color}
      data-session-number="1"
    >
      <div className="headline">
        <h2>Session {session.number}</h2>
        <h3>{session.title}</h3>
        <div id="component_menu_button"></div>
      </div>
      <ul>
        {session.components.map((component) => {
          const completedClass = component.completed ? "complete" : "";
          const unlockedClass = component.locked ? "" : "unlocked";
          const inProgressClass =
            component.number == componentNumber ? "in-progress" : "";

          return (
            <li
              key={component.id}
              className={`component ${inProgressClass} ${unlockedClass} ${completedClass}`}
              onClick={() => navigateToComponent(component)}
            >
              <h4 className="title">{component.title}</h4>
              <h4 className="index">{component.number}</h4>
              <p>
                <span className="duration">~{component.duration}</span>{" "}
                experience
              </p>
            </li>
          );
        })}
        <li className="minimize">
          <div id="minimize_button"></div>
        </li>
      </ul>
    </nav>
  );
};

export default SessionNav;
