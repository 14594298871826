import { FC } from "react";
import offSubtitlesIcon from "../../../images/off-subtitles.svg";
import onSubtitlesIcon from "../../../images/on-subtitles.svg";
import printIcon from "../../../images/print.svg";
import audioIcon from "../../../images/audio.svg";
import playIcon from "../../../images/play.svg";
import arrowDownIcon from "../../../images/arrow-down.svg";
import arrowLeftIcon from "../../../images/arrow-left.svg";
import linkIcon from "../../../images/link.svg";
import lockedIcon from "../../../images/locked.svg";
import sessionCompleteIcon from "../../../images/session-complete.svg";
import sessionIncompleteIcon from "../../../images/session-incomplete.svg";
import unlockedIcon from "../../../images/unlocked.svg";

type Icons =
  | "off-subtitles"
  | "on-subtitles"
  | "print"
  | "audio"
  | "play"
  | "arrow-down"
  | "arrow-left"
  | "link"
  | "locked"
  | "session-complete"
  | "session-incomplete"
  | "unlocked";

type IconMap = {
  [key in Icons]: any;
};

const iconMap: IconMap = {
  "off-subtitles": offSubtitlesIcon,
  "on-subtitles": onSubtitlesIcon,
  print: printIcon,
  audio: audioIcon,
  play: playIcon,
  "arrow-down": arrowDownIcon,
  "arrow-left": arrowLeftIcon,
  link: linkIcon,
  locked: lockedIcon,
  "session-complete": sessionCompleteIcon,
  "session-incomplete": sessionIncompleteIcon,
  unlocked: unlockedIcon,
};

interface ButtonWithIconProps {
  icon: string;
  hidden?: boolean;
  textContent: string;
  clickHandler: () => void;
}

const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  icon,
  hidden = false,
  textContent,
  clickHandler,
}) => {
  return hidden === false ? (
    <button onClick={() => clickHandler()}>
      {icon ? <img className="button-icon" src={iconMap[icon]} /> : null}
      {textContent}
    </button>
  ) : null;
};

export default ButtonWithIcon;
