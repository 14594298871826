import { FC } from "react";
import { Formik } from "formik";

interface Props {
  data: any;
  onSubmit: (values: any) => void;
}

const TriggersCopingForm: FC<Props> = ({ data, onSubmit }) => {
  const initialValues = {
    external_trigger_1: "",
    internal_trigger_1: "",
    external_trigger_2: "",
    internal_trigger_2: "",
    external_trigger_3: "",
    internal_trigger_3: "",
    external_trigger_4: "",
    internal_trigger_4: "",
    external_trigger_5: "",
    internal_trigger_5: "",
  };

  Object.assign(initialValues, data);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, handleChange, handleBlur }) => (
        <form id="triggersForm" onSubmit={handleSubmit}>
          <div className="container">
            <h2 className="text-center" id="title">
              How you cope with triggers
            </h2>
            <div className="row">
              <div className="col-xs-6">
                <h5 className="text-center" id="title">
                  External trigger and how you coped
                </h5>
              </div>
              <div className="col-xs-6">
                <h5 className="text-center" id="title">
                  Internal trigger and how you coped
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.external_trigger_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="external_trigger_1"
                    required
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.internal_trigger_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="internal_trigger_1"
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.external_trigger_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="external_trigger_2"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.internal_trigger_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="internal_trigger_2"
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.external_trigger_3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="external_trigger_3"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.internal_trigger_3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="internal_trigger_3"
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.external_trigger_4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="external_trigger_4"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.internal_trigger_4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="internal_trigger_4"
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.external_trigger_5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="external_trigger_5"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <div className="col-xs-6">
                <div className="form-group">
                  <textarea
                    value={values.internal_trigger_5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    name="internal_trigger_5"
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="submit-container">
              <button className="btn btn-block btn-primary">
                Submit your answers
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default TriggersCopingForm;
