import { useSearchParams } from "react-router-dom";
import ApiService from "../services/apiService";

const SignIn = () => {
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  if (token) {
    ApiService.instance().setUserToken(token);
    ApiService.instance().logActivity("sign-in", "");
  }

  return (
    <main className="main">
      <div className="signed-in-container">
        <h1 className="signed-in-header">
          Signed In: <span>{token}</span>
        </h1>
        <a href="/" className="signed-in-copy">
          Return to the home page
        </a>
      </div>
    </main>
  );
};

export default SignIn;
