import { useState, useEffect } from "react";

const StandaloneCaptions = ({ activeCues, textTrack }) => {
  const [cueText, setCueText] = useState("");

  useEffect(() => {
    textTrack.mode = "showing";

    const cueChangeHandler = () => {
      const cue = textTrack.activeCues[0];
      setCueText(cue ? cue.text : "");
    };

    textTrack.addEventListener("cuechange", cueChangeHandler);

    return () => {
      textTrack.removeEventListener("cuechange", cueChangeHandler);
    };
  }, [activeCues]);

  return (
    <div className="captions-container">
      <p className="cue-text">{cueText}</p>
    </div>
  );
};

export default StandaloneCaptions;
