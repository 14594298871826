import { FC, useState } from "react";
import { useFormik } from "formik";

interface Props {
  data: any;
  name: string;
  questionText: string;
  inputType: string;
  onSubmit: (values: any) => void;
}

const SingleQuestionForm: FC<Props> = ({
  data,
  name,
  questionText,
  inputType,
  onSubmit,
}) => {
  const [formName, setFormName] = useState(name);

  if (data["answer"] === undefined) data.answer = "";

  const formik = useFormik({
    initialValues: data,
    onSubmit: (values) => onSubmit(values),
    enableReinitialize: true,
  });

  // hack to force formik to reset internal value when field is empty
  if (formName !== name) {
    setFormName(name);
    formik.resetForm({ values: data });
  }

  const { values, handleChange, handleBlur } = formik;

  const inputElement = (values, handleChange, handleBlur) => {
    switch (inputType) {
      case "date-picker":
        return (
          <input
            value={values.answer}
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
            className="form-control"
            name="answer"
            required
          />
        );
      case "yes-no":
        return (
          <div className="form-group">
            <div className="inline-radio-container">
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                type="radio"
                id="achieved"
                name="answer"
                value="true"
                checked={values.answer === "true"}
              />
              <label htmlFor="achieved" className="radio-inline">
                Yes
              </label>
            </div>
            <div className="inline-radio-container">
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                type="radio"
                id="failed"
                name="answer"
                value="false"
                checked={values.answer === "false"}
              />
              <label htmlFor="failed" className="radio-inline">
                No
              </label>
            </div>
          </div>
        );
      case "text-area":
        return (
          <textarea
            value={values.answer}
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control"
            required
            rows={5}
            name="answer"
          ></textarea>
        );
      default:
        return (
          <input
            value={values.answer}
            onChange={handleChange}
            onBlur={handleBlur}
            name="answer"
            type="text"
            required
          />
        );
    }
  };

  return (
    <form id="questionForm" onSubmit={formik.handleSubmit}>
      <div className="container">
        <div className="row" id="printarea">
          <div className="wrapper col-md-12">
            <section>
              <h3>{questionText}</h3>
              {inputElement(values, handleChange, handleBlur)}
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col-md-offset-4 col-md-4">
            <button
              type="submit"
              name="submit_value_board_btn"
              className="btn btn-primary btn-block"
            >
              Submit Answer
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SingleQuestionForm;
