import { Component } from "react";

type Props = {
  children: JSX.Element;
};

type State = {
  hasError: boolean;
  redirecting: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      redirecting: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // catch 401 (not logged in) errors and redirect to login site
    if (error && error["code"] && error["code"] === "ERR_BAD_REQUEST") {
      this.setState({ redirecting: true });

      const encodedRequestPath = encodeURIComponent(window.location.pathname);
      const loginRedirectUrl = `${window["LOGIN_URL"]}?referrer=${encodedRequestPath}`;

      window.location.href = loginRedirectUrl;
    }

    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className="main">
          <div className="centered-error-container">
            {this.state.redirecting ? (
              <>
                <h1 className="error-title">Redirecting to login...</h1>
              </>
            ) : (
              <>
                <h1 className="error-title">Something went wrong.</h1>
                <a href="/" className="error-copy">
                  Return to the home page{" "}
                </a>
              </>
            )}
          </div>
        </main>
      );
    }

    return this.props.children;
  }
}
