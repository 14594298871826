import { FC, useState, useEffect } from "react";
import { Profile } from "../models/profile";
import { SuspenseResource } from "../services/apiService";
import jen from "../../images/jen-cut-out.png";

interface Props {
  resource: SuspenseResource<Profile>;
}

const SavingsBanner: FC<Props> = ({ resource }) => {
  const profile = resource.read();
  const [memoizedProfile, setMemoizedProfile] = useState(profile);

  useEffect(() => {
    const savingsChanged = Object.entries(profile.savings).some(
      ([key, value]) => value !== memoizedProfile.savings[key]
    );

    if (
      profile.completed_all != memoizedProfile.completed_all ||
      savingsChanged
    ) {
      setMemoizedProfile(profile);
    }
  }, [profile]);

  const {
    savings: {
      one_month: oneMonth,
      five_years: fiveYears,
      one_year_cut_back: oneYear,
    },
    completed_all: completedAll,
  } = memoizedProfile;

  const oneMonthSavings = oneMonth.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  const fiveYearsSavings = fiveYears.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  const oneYearSavings = oneYear.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  const phrases = [
    `If you went 1 month without smoking, you’d save ${oneMonthSavings}!`,
    `If you quit smoking today, in 5 years you’d save ${fiveYearsSavings}.`,
    `If you cut back smoking by half starting today, you’d save ${oneYearSavings} this year!`,
  ];

  const getRandomInt = (max: number = 3) => {
    return Math.floor(Math.random() * max);
  };

  return (
    !completedAll && (
      <section className="sayings">
        <div className="container">
          <img src={jen} alt="jen" />
          <p>{phrases[getRandomInt(phrases.length)]}</p>
        </div>
      </section>
    )
  );
};

export default SavingsBanner;
