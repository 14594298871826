import SpinnerSvg from "../../../images/spinner.svg";

const LoadingSpinner = () => {
  return (
    <div className="vertically-centered">
      <div className="horizontally-centered">
        <img src={SpinnerSvg} className="loading-spinner"/>
      </div>
    </div>
   );
}

export default LoadingSpinner;