import { FC, PureComponent, useEffect, useState } from "react";
import { FormDataItem } from "../../models/form-data-item";
import ApiService from "../../services/apiService";
import SingleQuestionForm from "./forms/SingleQuestionForm";
import TriggersCopingForm from "./forms/TriggersCopingForm";
import TriggersForm from "./forms/TriggersForm";
import ValuesForm from "./forms/ValuesForm";

interface Props {
  control: string;
  formName: string;
  params: any;
  onSkip: (step: number) => void;
  onComplete: () => void;
}

const Form: FC<Props> = ({ control, formName, params, onSkip, onComplete }) => {
  const emptyFormData: FormDataItem = { content: {} };
  const [formData, setFormData] = useState(emptyFormData);
  const useSkip: boolean =
    params.yesStep !== undefined && params.noStep !== undefined;

  useEffect(() => {
    const fetchData = async () => {
      const savedFormData = await ApiService.instance().getFormData(formName);
      setFormData(savedFormData);
    };

    fetchData(); // not using suspender due to known issues nesting
  }, [formName]);

  const handleSubmit = (values) => {
    ApiService.instance()
      .saveFormData(formName, values)
      .then(() => {
        if (useSkip) {
          const skipToStep =
            values.answer === "true" ? params.yesStep : params.noStep;
          onSkip(skipToStep);
        } else {
          onComplete();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getFormComponent = () => {
    switch (control) {
      case "values":
        return (
          <ValuesForm
            data={formData.content}
            onSubmit={(values) => handleSubmit(values)}
          />
        );
      case "triggers":
        return (
          <TriggersForm
            data={formData.content}
            showOptions={params.showOptions}
            triggerLabel={params.label}
            onSubmit={(values) => handleSubmit(values)}
          />
        );
      case "triggers-coping":
        return (
          <TriggersCopingForm
            data={formData.content}
            onSubmit={(values) => handleSubmit(values)}
          />
        );
      case "single":
        return (
          <SingleQuestionForm
            data={formData.content}
            name={formName}
            inputType={params.inputType}
            questionText={params.text}
            onSubmit={(values) => handleSubmit(values)}
          />
        );
    }
  };

  return (
    <div className="flexiquit-forms">
      <div id="tableid" className="values-table">
        {getFormComponent()}
      </div>
    </div>
  );
};

export default Form;
