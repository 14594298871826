import { Component } from "react";
import { Player, BigPlayButton, ControlBar } from "video-react";
import StandaloneCaptions from "../atoms/StandaloneCaptions";
import posterImage from "../../../images/video-poster.jpg";

interface Props {
  video: string;
  captions: string;
  audioOnly: boolean;
  playPosition?: number;
  triggerPlay?: boolean;
  disableControlBar?: boolean;
  showCaptions: boolean;
  standaloneCaptions?: boolean;
  onComplete: () => void;
}

class Video extends Component<Props> {
  videoBasePath = "https://eqqualflexiquit.sfo2.digitaloceanspaces.com";
  player = null;
  state = { srcChanged: false, englishTrack: null, hasFinished: false };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  componentWillUnmount() {
    if (this.player) {
      this.player = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.audioOnly !== this.props.audioOnly) {
      this.setState({ srcChanged: true });
    }

    if (this.props.triggerPlay) {
      this.player.play();
    }
  }

  handleStateChange(state) {
    if (state.ended && !this.state.hasFinished) {
      this.props.onComplete();
      this.setState({ hasFinished: true });
    }

    /* https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState */
    if (
      state.readyState == 4 &&
      this.state.srcChanged &&
      this.props.playPosition
    ) {
      this.setState({ srcChanged: false });
      this.player.seek(this.props.playPosition);
      this.player.play();
    }

    if (this.player) {
      const {
        video: {
          video: { textTracks },
        },
      } = this.player;

      const englishTrack = textTracks[0];
      englishTrack.mode = this.props.showCaptions ? "showing" : "hidden";

      this.setState({ englishTrack });
    }
  }

  showStandaloneCaptions = () => {
    return (
      this.player &&
      this.props.showCaptions &&
      this.props.standaloneCaptions &&
      this.state.englishTrack
    );
  };

  render() {
    const captionsSrc = `/captions/${this.props.captions}`;
    let videoSrc = `${this.videoBasePath}/${this.props.video}`;

    if (this.props.audioOnly) {
      const audioFilename = this.props.video.replace(".mp4", "_audio.mp4");
      videoSrc = `${this.videoBasePath}/audio/${audioFilename}`;
    }

    return (
      <>
        <Player
          ref={(player) => (this.player = player)}
          playsInline
          poster={posterImage}
          src={videoSrc}
          autoPlay={this.state.srcChanged}
        >
          <BigPlayButton position="center" />
          <track
            kind="captions"
            srcLang="en-US"
            label="English"
            default
            src={captionsSrc}
          />
          <ControlBar disableCompletely={this.props.disableControlBar} />
        </Player>
        {this.showStandaloneCaptions() && (
          <StandaloneCaptions
            activeCues={this.state.englishTrack.activeCues}
            textTrack={this.state.englishTrack}
          />
        )}
      </>
    );
  }
}

export default Video;
