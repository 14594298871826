import React, { FC } from "react";

interface GameBoardCellProps {
  index: number,
  revealed: boolean,
  selected: boolean,
  textContent: string | number,
};

const GameBoardCell : FC<GameBoardCellProps> = ({
   index,
   selected,
   textContent,
   revealed=false,
}: GameBoardCellProps) => {
  return (
    <div
      className={`game_cell ${selected ? 'selected' : ''}`}
    >
      <p className="game_cell_text">{ revealed ? textContent : (index + 1) }</p>
    </div>
  );
};

export default GameBoardCell;
