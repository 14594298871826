import { FC, useEffect, useState } from "react";
import GameBoardCell from "./GameBoardCell";
import Video from "../Video";
import { GameBoardResponses, maxAllowedSelectedCells } from "./constants";

interface GameBoardCell {
  index: number;
  revealed: boolean;
  selected: boolean;
  textContent: string | number;
}

enum GameBoardStates {
  "COACHING" = "coaching",
  "REVIEW_ALL" = "review_all",
}

interface Props {
  video: string;
  videoKey: string;
  captions: string;
  showCaptions: boolean;
  isLoadingStep: boolean;
  setAllowSkipToNext: Function;
  gameState: GameBoardStates;
  navigateContent: Function;
}

const GameOfLife: FC<Props> = ({
  video,
  videoKey,
  captions,
  gameState,
  showCaptions,
  isLoadingStep,
  navigateContent,
  setAllowSkipToNext,
}: Props) => {
  const initialCells = () => {
    const init: GameBoardCell[] = [];

    for (const index in GameBoardResponses) {
      init.push({
        index: parseInt(index),
        revealed: false,
        selected: false,
        textContent: GameBoardResponses[index],
      });
    }
    return init;
  };
  const [gameBoardCells, setGameBoardCells] = useState(initialCells());
  const [numSelected, setNumSelected] = useState<number>(0);
  const [boardFrozen, setBoardFrozen] = useState(false);

  const selectedCellCount = () =>
    gameBoardCells.filter((cell) => cell.selected).length;

  useEffect(() => {
    setNumSelected(selectedCellCount);
    updateGameBoard();
  }, [gameState]);

  const resetBoard = () => {
    const updatedBoardGameCells = gameBoardCells.map((cell) => {
      cell.revealed = false;
      cell.selected = false;
      return cell;
    });
    setNumSelected(0);
    setGameBoardCells([...updatedBoardGameCells]);
  };

  const showAllResponses = () => {
    const updatedBoardGameCells = gameBoardCells.map((cell) => {
      cell.selected = false;
      cell.revealed = true;
      return cell;
    });
    setGameBoardCells([...updatedBoardGameCells]);
  };

  const revealSelectedCells = () => {
    const updatedBoardGameCells = gameBoardCells.map((cell) => {
      if (cell.selected) {
        cell.revealed = true;
      } else {
        cell.revealed = false;
      }
      return cell;
    });

    setGameBoardCells([...updatedBoardGameCells]);
  };

  const updateGameBoard = () => {
    if (
      gameState === GameBoardStates.COACHING &&
      numSelected === maxAllowedSelectedCells
    ) {
      revealSelectedCells();
    } else if (gameState === GameBoardStates.COACHING) {
      resetBoard();
      setBoardFrozen(false);
      setNumSelected(0);
    } else if (gameState === GameBoardStates.REVIEW_ALL) {
      resetBoard();
      setBoardFrozen(false);
      setNumSelected(0);
      showAllResponses();
    }
  };

  const handleCellSelect = (index) => {
    if (boardFrozen || numSelected > maxAllowedSelectedCells) {
      return;
    }

    const cellSelected = gameBoardCells[index].selected;

    if (cellSelected) {
      gameBoardCells[index].selected = false;
      setNumSelected(numSelected - 1);
      setGameBoardCells([...gameBoardCells]);
    } else if (!cellSelected && numSelected <= maxAllowedSelectedCells) {
      gameBoardCells[index].selected = true;
      setNumSelected(numSelected + 1);
      setGameBoardCells([...gameBoardCells]);

      if (numSelected === maxAllowedSelectedCells) {
        if (gameState === GameBoardStates.REVIEW_ALL) {
          navigateContent(1);
        }

        revealSelectedCells();
        setBoardFrozen(true);
      }
    }
  };

  const handleVideoComplete = () => {
    setNumSelected(0);
    navigateContent(1);
  };

  return (
    <div className="game-of-life-container">
      <div className="game_board">
        {gameBoardCells.map(({ index, revealed, selected, textContent }) => {
          return (
            <div
              key={`${index}:${textContent[0]}`}
              onClick={() => handleCellSelect(index)}
            >
              <GameBoardCell
                index={index}
                revealed={revealed}
                selected={selected}
                textContent={textContent}
              />
            </div>
          );
        })}
      </div>
      {video && captions && !isLoadingStep && (
        <div
          className={`caption-video-container ${!showCaptions ? "hidden" : ""}`}
        >
          <Video
            video={video}
            key={videoKey}
            audioOnly={true}
            captions={captions}
            showCaptions={true}
            standaloneCaptions={true}
            disableControlBar={true}
            onComplete={handleVideoComplete}
            triggerPlay={boardFrozen}
          />
        </div>
      )}
    </div>
  );
};

export default GameOfLife;
