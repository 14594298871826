import { FC, useCallback, useRef, RefObject, useEffect } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

type Props = { heroRef: RefObject<HTMLDivElement> };

const Confetti: FC<Props> = ({ heroRef }) => {
  const refAnimationInstance = useRef(null);
  const brandColors = ['#F4A8BA', '#4B8ACC', '#A77FBB', '#F9C217', '#D76326', '#7DBD40'];

  const randomAngle = () => {
    return Math.floor(60 + Math.random() * 60);
  };

  useEffect(() => {
    heroRef.current.addEventListener("click", ({ x, y }) => {
      const origin = {
        x: x / heroRef.current.offsetWidth,
        y: y / (heroRef.current.offsetHeight),
      }

      makeShot(0.25, {
        origin,
        colors: brandColors,
        angle: randomAngle(),
        spread: 26,
        startVelocity: 55
      });
      makeShot(0.25, {
        origin,
        colors: brandColors,
        angle: randomAngle(),
        spread: 120,
        startVelocity: 55
      });
      makeShot(0.2, {
        origin,
        colors: brandColors,
        angle: randomAngle(),
        spread: 60,
      });
      makeShot(0.1, {
        origin,
        colors: brandColors,
        angle: randomAngle(),
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
    });

    /* first three shots when component loads */
    fire({x: 0.99, y: 1}, 120, brandColors);
    fire({x: 0.01, y: 1}, 60, brandColors);

    setTimeout(() => {
      fire({x: 0.99, y: 1}, 120, brandColors);
      fire({x: 0.01, y: 1}, 60, brandColors);
    }, 1000);

    setTimeout(() => {
      fire({x: 0.99, y: 1}, 120, brandColors);
      fire({x: 0.01, y: 1}, 60, brandColors);
    }, 2000);

  }, []);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = (particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        particleCount: Math.floor(200 * particleRatio)
      });
  }

  const fire = (origin, angle, brandColors, startVelocity=45) => {
    makeShot(0.25, {
      origin,
      colors: brandColors,
      angle,
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      origin,
      colors: brandColors,
      angle,
      startVelocity,
      spread: 60
    });

    makeShot(0.35, {
      origin,
      colors: brandColors,
      angle,
      startVelocity,
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      origin,
      colors: brandColors,
      angle,
      spread: 120,
      startVelocity: 75,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      origin,
      colors: brandColors,
      angle,
      spread: 120,
      startVelocity: 65
    });
  };

  return (
    <ReactCanvasConfetti className="react-confetti" refConfetti={getInstance} />
  );
}

export default Confetti;
