import { FC } from "react";
import { Session } from "../models/session";
import { useNavigate } from "react-router-dom";
import ApiService from "../services/apiService";

type CardProps = {
  title: string;
  locked: boolean;
  inProgress: boolean;
  session: Session;
  progress: number;
  duration: string;
  color: string;
};

const SessionGridCard: FC<CardProps> = ({
  title,
  color,
  locked = true,
  inProgress,
  duration,
  progress,
  session,
}: CardProps) => {
  const resumeComponentNumber = session.resume_component_number;
  const navigate = useNavigate();

  const handleClick = () => {
    let eventType;

    if (inProgress) {
      eventType = "resume-session";
    } else if (session.completed) {
      eventType = "re-launch-session";
    } else {
      eventType = "start-session";
    }

    ApiService.instance().logActivity(eventType, `session: ${session.number}`);
    navigate(
      `/sessions/${session.number}/modules/${resumeComponentNumber}/steps/1`
    );
  };

  return (
    <li className={`card ${color}`}>
      <div
        className={`
          ${locked ? "" : "unlocked"}
          ${inProgress ? "in-progress" : session.completed ? "completed" : ""}
        `}
      >
        <div className="image-container">
          <img
            src={`../../../images/session-${session.number}.png`}
            alt={`session ${session.number}`}
          />
        </div>
        <div className="progress-bar">
          <span
            style={{ width: `${progress}%` }}
            className={`indicator completed-${progress}`}
          ></span>{" "}
        </div>
        <h3>Session {session.number}</h3>
        <h4>{title}</h4>
        <p className="experience-duration">~ {duration} experience</p>

        <a onClick={() => handleClick()} className={locked ? "hidden" : ""}>
          <span>
            {inProgress ? "Resume" : session.completed ? "Re-launch" : "Start"}
          </span>
        </a>
      </div>
    </li>
  );
};

export default SessionGridCard;
