const Footer = () => {
  return (
    <footer id="footer">
      <ul>
        <li className="contact">
          <p>
            Need support? Found any bugs? v2.0
            <span className="email"> Contact us: </span>
            <a href="mailto:eqqual@fredhutch.org">EQQUAL@fredhutch.org</a>
          </p>
        </li>
        <li className="copyright">
          <p>
            Copyright &copy; 2023 EQQUAL is a registered trademark of
            <span className="with-logo">Fred Hutch</span>.
          </p>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
