import ApiService from "./apiService";

const printValuesTable = async (formKey) => {
  let token = ApiService.instance().token;
  window.open(
    `${window.location.origin}/print_values?token=${token}&form_key=${formKey}`,
    "Values Table",
    "status=1"
  );
};

export default printValuesTable;
