import { FC } from "react";
import { Formik } from "formik";

interface Props {
  data: any;
  onSubmit: (values: any) => void;
}

const ValuesForm: FC<Props> = ({ data, onSubmit }) => {
  const initialValues = {
    goal_health: "",
    actual_health: "",
    todo_health: "",
    goal_family: "",
    actual_family: "",
    todo_family: "",
    goal_friendship: "",
    actual_friendship: "",
    todo_friendship: "",
    goal_relationships: "",
    actual_relationships: "",
    todo_relationships: "",
    goal_work: "",
    actual_work: "",
    todo_work: "",
  };

  Object.assign(initialValues, data);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, handleChange, handleBlur }) => (
        <form id="tableSubmit" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row" id="printarea">
              <div className="wrapper col-md-12">
                <section className="values">
                  <p>
                    For each of the value areas, first rate importance from 0
                    (not at all important) to 10 (extremely important). Then
                    rate how much time and energy you're spending on it, from 0
                    (none at all) to 10 (a lot of time). Next, write down at
                    least one thing you can do that would help you move closer
                    to the way you want to live.
                  </p>
                  <h3>Health</h3>
                  <ul>
                    <li>
                      <label>How important I want it to be</label>
                      <input
                        value={values.goal_health}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="goal_health"
                        type="number"
                        min="0"
                        max="10"
                        placeholder="e.g. 10"
                        required
                      />
                    </li>
                    <li>
                      <label>
                        How much time and energy I'm spending on it currently
                      </label>
                      <input
                        value={values.actual_health}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="actual_health"
                        type="number"
                        min="0"
                        max="10"
                        placeholder="e.g. 5"
                        required
                      />
                    </li>
                    <li>
                      <label>What I can do to decrease the difference</label>
                      <textarea
                        value={values.todo_health}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="todo_health"
                        rows={3}
                        cols={40}
                        placeholder="e.g. Healthier eating, more exercise, decreasing smoking"
                        required
                      ></textarea>
                    </li>
                  </ul>
                </section>
                <section className="values secondary right-spacing">
                  <h3>Family</h3>
                  <ul>
                    <li>
                      <label>How important I want it to be</label>
                      <input
                        value={values.goal_family}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="goal_family"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>
                        How much time and energy I'm spending on it currently
                      </label>
                      <input
                        value={values.actual_family}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="actual_family"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>What I can do to decrease the difference</label>
                      <textarea
                        value={values.todo_family}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="todo_family"
                        rows={3}
                        cols={40}
                        required
                      ></textarea>
                    </li>
                  </ul>
                </section>
                <section className="values secondary">
                  <h3>Friendships</h3>
                  <ul>
                    <li>
                      <label>How important I want it to be</label>
                      <input
                        value={values.goal_friendship}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="goal_friendship"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>
                        How much time and energy I'm spending on it currently
                      </label>
                      <input
                        value={values.actual_friendship}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="actual_friendship"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>What I can do to decrease the difference</label>
                      <textarea
                        value={values.todo_friendship}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="todo_friendship"
                        rows={3}
                        cols={40}
                        required
                      ></textarea>
                    </li>
                  </ul>
                </section>
                <section className="values secondary right-spacing">
                  <h3>Relationships</h3>
                  <ul>
                    <li>
                      <label>How important I want it to be</label>
                      <input
                        value={values.goal_relationships}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="goal_relationships"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>
                        How much time and energy I'm spending on it currently
                      </label>
                      <input
                        value={values.actual_relationships}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="actual_relationships"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>What I can do to decrease the difference</label>
                      <textarea
                        value={values.todo_relationships}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="todo_relationships"
                        rows={3}
                        cols={40}
                        required
                      ></textarea>
                    </li>
                  </ul>
                </section>
                <section className="values secondary">
                  <h3>Work/Education</h3>
                  <ul>
                    <li>
                      <label>How important I want it to be</label>
                      <input
                        value={values.goal_work}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="goal_work"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>
                        How much time and energy I'm spending on it currently
                      </label>
                      <input
                        value={values.actual_work}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="actual_work"
                        type="number"
                        min="0"
                        max="10"
                        required
                      />
                    </li>
                    <li>
                      <label>What I can do to decrease the difference</label>
                      <textarea
                        value={values.todo_work}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="todo_work"
                        rows={3}
                        cols={40}
                        required
                      ></textarea>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-4 col-md-4">
                <button
                  type="submit"
                  name="submit_value_board_btn"
                  className="btn btn-primary btn-block"
                >
                  Submit Your Value Board
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ValuesForm;
