const AboutUs = () =>  {
  return (
    <section id="about">
      <h2>About Us</h2>
        <p>
          We are a team of researchers and experts in the psychology and design of behavior change interventions for smoking and vaping.
          Some members of our team are young adults, members of the LGBTQ+ community, and former tobacco users, although we never assume that our experiences match anyone else’s.
          We developed the Empowered, Queer, Quitting, and Living (EQQUAL) program with input from LGBTQ+ young adults at varying levels of readiness to quit smoking.
          Our goal is to help you choose the life you want to live and decide whether that life should include smoking (and vaping) or not. We’ll show you tools that you can use if and when you’re ready to quit, but the exact path you choose is up to you.
        </p>
      <p>Learn more about the team here: <a href="https://research.fhcrc.org/tobacco-related-health-disparities-research-group/en.html" target="_blank">research.fhcrc.org/tobacco-related-health-disparities-research-group/en.html</a>.</p>
    </section>
   );
}

export default AboutUs;
