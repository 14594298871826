import { Suspense } from "react";
import ApiService from "../services/apiService";
import Hero from "./Hero";
import SessionGrid from "./SessionGrid";
import AboutUs from "./AboutUs";
import SavingsBanner from "./SavingsBanner";
import LoadingSpinner from "./atoms/LoadingSpinner";
import { useSearchParams } from "react-router-dom";

const Home = () => {
  const [searchParams] = useSearchParams();
  const profileResource = ApiService.instance().getProfile(searchParams);

  return (
    <main className="home">
      <Suspense fallback={<LoadingSpinner />}>
        <Hero resource={profileResource} />
        <SessionGrid resource={profileResource} />
        <SavingsBanner resource={profileResource} />
        <AboutUs />
      </Suspense>
    </main>
  );
};

export default Home;
