import { FC } from "react";
import { ExternalLink, FileDownload } from "../models/step";
import ApiService from "../services/apiService";

interface Props {
  downloads: [FileDownload];
  links: [ExternalLink];
}

const ResourceLinks: FC<Props> = ({ downloads, links }) => {
  const fireTagEvent = (item) => {
    ApiService.instance().logActivity(
      "download-triggered",
      `download: ${item.name}, filename: ${item.file}`
    );
  };

  return (
    <div id="download_pdf">
      <h5>Additional Resources:</h5>
      {links.map((item) => {
        return (
          <a
            key={item.name}
            href={item.link}
            className="btn btn-primary btn-block link"
            target="_blank"
          >
            {item.name}
          </a>
        );
      })}
      {downloads.map((item) => {
        return (
          <a
            key={item.name}
            className={`btn btn-primary btn-block session-five ${item.icon}`}
            target="_blank"
            href={`/downloads/${item.file}`}
            onClick={() => fireTagEvent(item)}
          >
            {item.name}
          </a>
        );
      })}
    </div>
  );
};

export default ResourceLinks;
