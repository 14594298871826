import { FC } from "react";
import { Formik } from "formik";

interface Props {
  data: any;
  showOptions: boolean;
  triggerLabel: string;
  onSubmit: (values: any) => void;
}

const TriggersForm: FC<Props> = ({
  data,
  showOptions,
  triggerLabel,
  onSubmit,
}) => {
  const initialValues = {
    trigger_1: "",
    trigger_1_option: "",
    trigger_2: "",
    trigger_2_option: "",
    trigger_3: "",
    trigger_3_option: "",
    trigger_4: "",
    trigger_4_option: "",
    trigger_5: "",
    trigger_5_option: "",
  };

  Object.assign(initialValues, data);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, handleChange, handleBlur }) => (
        <form id="triggersForm" onSubmit={handleSubmit}>
          <div className="container">
            <div className="row full">
              <div className="col-xs-12">
                <h2 className="text-center">
                  {showOptions ? (
                    <>
                      Write next to each box a thing you could do to deal with
                      each trigger in order to not smoke or vape.
                    </>
                  ) : (
                    <>
                      Write 5 {triggerLabel.toLowerCase()} triggers that make
                      you want to smoke.
                    </>
                  )}
                </h2>
              </div>
            </div>
            <div className={`row ${showOptions ? "" : "full"}`}>
              <div className="form-column">
                <div className="form-group">
                  <label className="triggers">{triggerLabel} Trigger 1</label>
                  <input
                    value={values.trigger_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    name="trigger_1"
                    required
                  />
                </div>
              </div>
              {showOptions && (
                <div className="form-column">
                  <div className="form-group">
                    <label className="triggers">Option 1</label>
                    <input
                      value={values.trigger_1_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      name="trigger_1_option"
                      required
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`row ${showOptions ? "" : "full"}`}>
              <div className="form-column">
                <div className="form-group">
                  <label className="triggers">{triggerLabel} Trigger 2</label>
                  <input
                    value={values.trigger_2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    name="trigger_2"
                  />
                </div>
              </div>
              {showOptions && (
                <div className="form-column">
                  <div className="form-group">
                    <label className="triggers">Option 2</label>
                    <input
                      value={values.trigger_2_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      name="trigger_2_option"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`row ${showOptions ? "" : "full"}`}>
              <div className="form-column">
                <div className="form-group">
                  <label className="triggers">{triggerLabel} Trigger 3</label>
                  <input
                    value={values.trigger_3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    name="trigger_3"
                  />
                </div>
              </div>
              {showOptions && (
                <div className="form-column">
                  <div className="form-group">
                    <label className="triggers">Option 3</label>
                    <input
                      value={values.trigger_3_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      name="trigger_3_option"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`row ${showOptions ? "" : "full"}`}>
              <div className="form-column">
                <div className="form-group">
                  <label className="triggers">{triggerLabel} Trigger 4</label>
                  <input
                    value={values.trigger_4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    name="trigger_4"
                  />
                </div>
              </div>
              {showOptions && (
                <div className="form-column">
                  <div className="form-group">
                    <label className="triggers">Option 4</label>
                    <input
                      value={values.trigger_4_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      name="trigger_4_option"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`row ${showOptions ? "" : "full"}`}>
              <div className="form-column">
                <div className="form-group">
                  <label className="triggers">{triggerLabel} Trigger 5</label>
                  <input
                    value={values.trigger_5}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="form-control"
                    name="trigger_5"
                  />
                </div>
              </div>
              {showOptions && (
                <div className="form-column">
                  <div className="form-group">
                    <label className="triggers">Option 5</label>
                    <input
                      value={values.trigger_5_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      name="trigger_5_option"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="button-container">
              <button
                type="submit"
                name="submit_value_board_btn"
                className="btn btn-primary btn-block"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default TriggersForm;
