import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Profile } from "../models/profile";
import ApiService, { SuspenseResource } from "../services/apiService";
import DartBoard from "./content/DartBoard";
import Form from "./content/Form";
import Testimonial from "./content/Testimonial";
import GameOfLife from "./content/game-of-life/GameOfLife";
import Video from "./content/Video";
import ResourceLinks from "./ResourceLinks";
import { ExternalLink, FileDownload } from "../models/step";
import ButtonWithIcon from "./atoms/ButtonWithIcon";
import printValuesTable from "../services/printUtil";

interface Props {
  sessionNumber: number;
  componentNumber: number;
  stepNumber: number;
  resource: SuspenseResource<Profile>;
}

const ContentComponents: FC<Props> = ({
  sessionNumber,
  componentNumber,
  stepNumber,
  resource,
}) => {
  const navigate = useNavigate();
  const [showCaptions, setShowCaptions] = useState(false);
  const [audioOnly, setAudioOnly] = useState(false);
  const [playPosition, setPlayPosition] = useState(null);
  const [allowSkipToNext, setAllowSkipToNext] = useState(false);
  const [rewardText, setRewardText] = useState(null);
  const [isLoadingStep, setIsLoadingStep] = useState(false);

  const videoRef = useRef(null);
  const sessions = resource.read().sessions;
  const session = sessions.find((item) => item.number == sessionNumber);

  if (!session) throw new Error("invalid session number");

  if (session.locked) throw new Error("something went wrong");

  const component = session.components.find(
    (item) => item.number == componentNumber
  );

  if (!component) throw new Error("invalid component number");

  const step = component.steps.find((item) => item.number == stepNumber);

  if (!step) throw new Error("invalid step number");
  if (component.locked) throw new Error("something went wrong");

  let downloads: [FileDownload] = step.params.downloads
    ? step.params.downloads
    : new Array<FileDownload>();
  let links: [ExternalLink] = step.params.links
    ? step.params.links
    : new Array<ExternalLink>();
  let stepCompleted = null;

  useEffect(() => {
    stepCompleted = ApiService.instance().getStepCompleted(
      session.number,
      component.number,
      step.number
    );

    setAllowSkipToNext(stepCompleted || step.content_type === "testimonial");
    fetchPossibleBlockersReward();
  }, [step.id]);

  const contentComponent = () => {
    switch (step.content_type) {
      case "game-of-life":
        return (
          <GameOfLife
            isLoadingStep={isLoadingStep}
            videoKey={`${session.number}-${component.number}-${step.number}`}
            showCaptions={showCaptions}
            video={step.params.video}
            captions={step.params.captions}
            gameState={step.params.state}
            setAllowSkipToNext={setAllowSkipToNext}
            navigateContent={navigateContent}
          />
        );
      case "form":
        return (
          <Form
            control={step.params.control}
            formName={step.params.name}
            params={step.params}
            onSkip={(step) => skipToStep(step)}
            onComplete={() => navigateContent(1)}
          />
        );
      case "testimonial":
        return <Testimonial image={step.params.image} />;
      case "dart-board":
        return (
          <DartBoard
            boldText={step.params.boldText}
            navigateContent={navigateContent}
          />
        );
      default:
        return (
          <Video
            key={`video-${step.id}`}
            ref={videoRef}
            video={step.params.video}
            captions={step.params.captions}
            showCaptions={showCaptions}
            audioOnly={audioOnly}
            playPosition={playPosition}
            onComplete={() => navigateContent(1)}
          />
        );
    }
  };

  const saveCompletedComponent = async (componentId: number) => {
    return await ApiService.instance().saveComponentProgress(componentId);
  };

  const skipToStep = async (stepNumber: number) => {
    let nextStep = stepNumber;
    let nextComponent = componentNumber;

    if (nextStep > component.steps.length) {
      nextComponent += 1;

      await saveCompletedComponent(component.id);

      if (nextComponent <= session.components.length) {
        nextStep = 1;
      } else {
        return; // at last step of session
      }
    }

    ApiService.instance().saveStepCompleted(
      session.number,
      component.number,
      step.number
    );

    /* https://github.com/mobyinc/EQQUAL-V2/pull/14#issuecomment-1414429874 */
    setTimeout(() => {
      navigate(
        `/sessions/${sessionNumber}/modules/${nextComponent}/steps/${nextStep}`
      );
    }, 800);

    console.log(
      `jump to session: ${sessionNumber}, component: ${nextComponent}, step: ${nextStep}`
    );
  };

  const navigateContent = async (delta: number) => {
    setIsLoadingStep(true);

    let nextStep = stepNumber + delta;
    let nextComponent = componentNumber;

    /* handle skips for any step that specifies one. [ex. this will skip over several "failed to quit" steps] */
    if (step.params.skipToStep !== undefined && delta > 0) {
      nextStep = step.params.skipToStep;
    }

    if (nextStep === 0) {
      nextComponent -= 1;

      if (nextComponent > 0) {
        const previousComponent = session.components.find(
          (item) => item.number == nextComponent
        );
        nextStep = previousComponent.steps.length; // go to last step of previous component
      } else {
        return; // at very first step of session
      }
    } else if (nextStep > component.steps.length) {
      nextComponent += 1;

      await saveCompletedComponent(component.id);

      if (nextComponent <= session.components.length) {
        nextStep = 1;
      } else {
        return; // at very last step of session
      }
    }

    console.log(
      `navigate to session: ${sessionNumber}, component: ${nextComponent}, step: ${nextStep}`
    );

    // mark step complete if navigating forward
    if (delta > 0) {
      try {
        await ApiService.instance().saveStepCompleted(
          session.number,
          component.number,
          step.number
        );
      } catch (error) {
        console.error("Failed to save step completion:", error);
      }
    }

    navigate(
      `/sessions/${sessionNumber}/modules/${nextComponent}/steps/${nextStep}`
    );
    setIsLoadingStep(false);
  };

  const canNavigateContentPrevious = () => {
    return component.number > 1 || step.number > 1;
  };

  const canNavigateContentNext = () => {
    return (
      (step.content_type == "video" || step.content_type == "testimonial") &&
      (component.number < session.components.length ||
        step.number < component.steps.length)
    );
  };

  const isLastStep = () => {
    return (
      component.number >= session.components.length &&
      step.number >= component.steps.length
    );
  };

  const navigateHome = async () => {
    await saveCompletedComponent(component.id);
    navigate("/");
  };

  const handlePrint = async () => {
    ApiService.instance().logActivity("print-values", "");

    let formKey = "";

    if (session.number === 2 && component.number === 1 && step.number === 3) {
      formKey = "values-review";
    } else {
      formKey = "values";
    }

    printValuesTable(formKey);
  };

  const showPrintButton = () => {
    return (
      (session.number === 1 && component.number === 3 && step.number === 1) ||
      (session.number === 2 && component.number === 1 && step.number === 3)
    );
  };

  const showGameOfLifeCaptionsButton = () => {
    return session.number === 1 && component.number === 1 && step.number === 3;
  };

  const fetchPossibleBlockersReward = async () => {
    if (session.number === 5 && component.number === 1 && step.number === 3) {
      const rewardText = await ApiService.instance().getFormData(
        "possible-blockers-reward"
      );

      const fullRewardText = `🏆 ${rewardText.content.answer}!`;
      setRewardText(fullRewardText);
    } else {
      setRewardText(null);
    }
  };

  const handleCaptionsToggle = () => {
    ApiService.instance().logActivity(
      `captions-${!showCaptions ? "on" : "off"}`,
      `session: ${session.number}, module: ${component.number}, step: ${step.number}`
    );
    setShowCaptions(!showCaptions);
  };

  const handleAudioOnlyToggle = () => {
    ApiService.instance().logActivity(
      `audio-only-${!audioOnly ? "on" : "off"}`,
      `session: ${session.number}, module: ${component.number}, step: ${step.number}`
    );

    const {
      current: {
        player: {
          manager: {
            video: {
              props: {
                player: { currentTime, hasStarted },
              },
            },
          },
        },
      },
    } = videoRef;

    if (hasStarted) {
      setPlayPosition(currentTime);
    }

    setAudioOnly(!audioOnly);
  };

  return (
    <div className="step">
      <h4 id="component_title">{component.title}</h4>
      <p>
        <span className="duration">~{component.duration}</span> experience
      </p>
      <div className="session_wrapper">
        <div id="flexiquit">{contentComponent()}</div>
        {rewardText && (
          <div id="reward-container">
            <h2 id="reward">{rewardText}</h2>
          </div>
        )}
      </div>
      <div className="nav-buttons">
        <button
          disabled={!canNavigateContentPrevious()}
          className="prev"
          onClick={() => navigateContent(-1)}
        >
          Previous
        </button>

        {showGameOfLifeCaptionsButton() && (
          <div className="only-captions content-options">
            <ButtonWithIcon
              clickHandler={() => handleCaptionsToggle()}
              icon={showCaptions ? "on-subtitles" : "off-subtitles"}
              textContent={showCaptions ? "Hide Captions" : "Show Captions"}
            />
          </div>
        )}

        {step.content_type === "video" && (
          <div className="content-options">
            <ButtonWithIcon
              icon={"print"}
              clickHandler={() => handlePrint()}
              textContent={"Print Your Values"}
              hidden={!showPrintButton()}
            />
            <ButtonWithIcon
              clickHandler={() => handleCaptionsToggle()}
              icon={showCaptions ? "on-subtitles" : "off-subtitles"}
              textContent={showCaptions ? "Hide Captions" : "Show Captions"}
            />
            <ButtonWithIcon
              icon={!audioOnly ? "audio" : "play"}
              clickHandler={() => handleAudioOnlyToggle()}
              textContent={audioOnly ? "Show Video" : "Audio Only Mode"}
            />
          </div>
        )}

        {isLastStep() ? (
          <button className="next" onClick={() => navigateHome()}>
            Home
          </button>
        ) : (
          <button
            className="next"
            disabled={!allowSkipToNext}
            onClick={() => navigateContent(1)}
            style={{
              visibility: canNavigateContentNext() ? "visible" : "hidden",
            }}
          >
            Next
          </button>
        )}
      </div>
      {(downloads.length > 0 || links.length > 0) && (
        <ResourceLinks downloads={downloads} links={links} />
      )}
    </div>
  );
};

export default ContentComponents;
