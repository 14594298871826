import axios, { AxiosResponse } from "axios";
import { FormDataItem } from "../models/form-data-item";
import { Profile } from "../models/profile";

export interface SuspenseResource<T> {
  read(): T;
}

export default class ApiService {
  token: string | null = null;

  static instance(): ApiService {
    if (apiInstance === null) {
      apiInstance = new ApiService();
    }

    return apiInstance;
  }

  constructor() {
    axios.defaults.baseURL = "/api";
    this.setUserToken(localStorage.getItem("token"));
  }

  setUserToken(token: string | null) {
    this.token = token;
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  getProfile(searchParams: URLSearchParams): SuspenseResource<Profile> {
    // check to see if credentials are present
    const token = searchParams.get("token");

    if (token) {
      return this.wrapPromise(this.signIn(token));
    }

    return this.wrapPromise(axios.get("/sessions/progress/all"));
  }

  signIn(token: string): Promise<AxiosResponse> {
    return axios.post("/sign-in", { token }).then((response) => {
      if (response.status == 200) {
        this.setUserToken(token);
      }
      return response;
    });
  }

  saveStepCompleted(session: number, component: number, step: number) {
    const key = `${session}-${component}-${step}`;
    let storageObj = localStorage.getItem("steps")
      ? JSON.parse(localStorage.getItem("steps"))
      : {};
    storageObj[key] = 1;
    localStorage.setItem("steps", JSON.stringify(storageObj));
  }

  getStepCompleted(session: number, component: number, step: number): boolean {
    const key = `${session}-${component}-${step}`;
    let storageObj = localStorage.getItem("steps")
      ? JSON.parse(localStorage.getItem("steps"))
      : {};
    return storageObj[key];
  }

  async getFormData(key: string): Promise<FormDataItem> {
    const response = await axios.get(`/form_records/${key}`);
    return response.data;
  }

  async saveFormData(key: string, values: any) {
    return await axios.post(`/form_records/${key}`, { key, content: values });
  }

  async saveComponentProgress(componentId: number) {
    return await axios.post("/completed_components", {
      component_id: componentId,
    });
  }

  async logActivity(action: string, args: string) {
    return await axios.post("/logs", {
      action_name: action,
      args,
    });
  }

  private wrapPromise<T>(
    promise: Promise<AxiosResponse<any, any>>
  ): SuspenseResource<T> {
    let status = "pending";
    let response: T;

    const suspender = promise.then(
      (res) => {
        status = "success";
        response = res.data as T;
      },
      (err) => {
        status = "error";
        response = err;
      }
    );

    const read = () => {
      switch (status) {
        case "pending":
          throw suspender;
        case "error":
          throw response;
        default:
          return response;
      }
    };

    return { read };
  }
}

let apiInstance: ApiService = new ApiService();
